<template>
    <v-app>
        <v-container :class="preloader ? 'hidden-scroll' : ''">
            <div class="main-page">
                <div ref="content" class="sub-page">
                    <div class="header">
                        <div class="logo">
                            <img
                                v-if="getSettingsDetails.logo"
                                :src="`${path}/client_logo.png`"
                                alt="entelli logo"
                            />
                        </div>
                        <div class="header__text">
                            <span class="header__text--line--1">{{
                                getSettingsDetails.companyName
                            }}</span>
                            <span class="header__text--line--2"
                                >{{ `${$t('service:str')} `
                                }}{{ getSettingsDetails.companyAddress }},
                                {{ getSettingsDetails.city }}</span
                            >
                            <span class="header__text--line--3"
                                >{{ $t('service:tel') }}
                                {{
                                    getSettingsDetails.companyServicePhoneNumber
                                }}</span
                            >

                            <span class="header__text--line--5"
                                ><a
                                    :href="
                                        getSettingsDetails.companyServiceEmail
                                    "
                                    >{{
                                        getSettingsDetails.companyServiceEmail
                                    }}</a
                                ></span
                            >
                        </div>
                    </div>
                    <div class="date-info">
                        <span
                            >{{ getSettingsDetails.companyCity }}, dnia
                            {{ currentDate }}</span
                        >
                    </div>
                    <div class="estimate__container">
                        <div class="estimate__container__topic">
                            <span>Wycena usług serwisu</span>
                        </div>
                        <div class="estimate__container__costs__wrapper">
                            <div class="column-1">
                                <div class="column__details">
                                    <img
                                        src="@/assets/img/global/building.png"
                                        alt="entelli logo"
                                    />
                                    <span class="line-1">Klient</span>
                                    <span class="text-bold">{{
                                        `${$get(
                                            getServiceDetails,
                                            'device.client.name',
                                            ''
                                        )}`
                                    }}</span>
                                </div>
                                <div class="column__details">
                                    <img
                                        src="@/assets/img/global/printer.png"
                                        alt="entelli logo"
                                    />
                                    <span class="line-1">Urządzenie</span>
                                    <span
                                        ><span class="text-bold">Model:</span
                                        ><span class="text-normal">
                                            {{
                                                `${$get(
                                                    getServiceDetails,
                                                    'device.model.manufacturer',
                                                    ''
                                                )}`
                                            }}
                                            {{
                                                `${$get(
                                                    getServiceDetails,
                                                    'device.model.model',
                                                    ''
                                                )}`
                                            }}</span
                                        >
                                    </span>
                                    <span
                                        ><span class="text-bold">S/N:</span>
                                        <span class="text-normal">
                                            {{
                                                `${$get(
                                                    getServiceDetails,
                                                    'device.sn',
                                                    ''
                                                )}`
                                            }}</span
                                        ></span
                                    >
                                    <span
                                        ><span class="text-bold">ID:</span>
                                        <span class="text-normal">
                                            {{
                                                `${$get(
                                                    getServiceDetails,
                                                    'index',
                                                    ''
                                                )}`
                                            }}</span
                                        ></span
                                    >
                                </div>
                            </div>
                            <div class="column-2">
                                <div class="column__details">
                                    <span class="line-1">Zakres naprawy</span>

                                    <div class="mt-3 min-wrapper">
                                        <span class="text-bold"
                                            >Części, materiały eksploatacyjne:
                                        </span>
                                        <span class="text-cost"
                                            >{{
                                                `${$get(
                                                    getServiceDetails,
                                                    'estimates.parts.total',
                                                    ''
                                                ).toFixed(2)}`
                                            }}
                                            PLN + 23% VAT</span
                                        >
                                    </div>
                                    <span>
                                        <div class="mt-2"></div>
                                        <span class="text-normal">
                                            <span
                                                v-for="item in getServiceDetails
                                                    .estimates.parts
                                                    .estimatesParts"
                                                :key="item._id"
                                                >{{ item.name }},
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div class="mt-3 break-line"></div>
                                <div class="column__details">
                                    <div class="min-wrapper">
                                        <span class="text-bold">Usługi: </span>
                                        <span class="text-cost"
                                            >{{
                                                `${$get(
                                                    getServiceDetails,
                                                    'estimates.chooseService.total',
                                                    ''
                                                ).toFixed(2)}`
                                            }}
                                            PLN + 23% VAT</span
                                        >
                                    </div>
                                    <span>
                                        <div class="mt-2"></div>
                                        <span class="text-normal">
                                            <span
                                                v-for="item in getServiceDetails
                                                    .estimates.chooseService
                                                    .estimatesServices"
                                                :key="item._id"
                                                >{{ item.field }},
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div class="mt-3 break-line"></div>

                                <div class="column__details">
                                    <div class="mt-2 min-wrapper">
                                        <span class="text-bold"
                                            >Transport:
                                        </span>
                                        <span class="text-cost"
                                            >{{
                                                `${$get(
                                                    getServiceDetails,
                                                    'estimates.transportConfig.costOfTransport',
                                                    ''
                                                ).toFixed(2)}`
                                            }}
                                            PLN + 23% VAT</span
                                        >
                                    </div>
                                </div>

                                <div class="mt-3 break-line"></div>

                                <div class="column__details">
                                    <div class="mt-2 min-wrapper">
                                        <span class="text-summary"
                                            >RAZEM ZA NAPRAWĘ
                                        </span>
                                        <span class="text-summary"
                                            >{{
                                                getSummaryCost(
                                                    $get(
                                                        getServiceDetails,
                                                        'estimates.parts.total',
                                                        0
                                                    ),
                                                    $get(
                                                        getServiceDetails,
                                                        'estimates.chooseService.total',
                                                        0
                                                    ),
                                                    $get(
                                                        getServiceDetails,
                                                        'estimates.transportConfig.costOfTransport',
                                                        0
                                                    )
                                                )
                                            }}
                                            PLN + 23% VAT</span
                                        >
                                    </div>
                                </div>

                                <div class="column__details">
                                    <div class="mt-2 min-wrapper">
                                        <span class="text-bold"
                                            >Termin ważności wyceny:
                                        </span>
                                        <span class="text-cost"
                                            >do
                                            {{
                                                `${$get(
                                                    getServiceDetails,
                                                    'estimates.comments.estimateValidTo',
                                                    ''
                                                )}`
                                            }}
                                        </span>
                                    </div>
                                </div>

                                <div class="column__details">
                                    <span
                                        ><span class="mt-2 text-normal">
                                            <span
                                                class="column__details--certificate-info"
                                                >{{
                                                    `${$get(
                                                        getSettingsDetails,
                                                        'estimatesPDFGuaranteText',
                                                        ''
                                                    )}`
                                                }}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div class="column__details">
                                    <span
                                        ><span class="line-1">Uwagi</span
                                        ><span class="text-normal">
                                            <div class="cost">
                                                <span>
                                                    {{
                                                        `${$get(
                                                            getServiceDetails,
                                                            'estimates.comments.estimateAdditionalContent',
                                                            ''
                                                        )}`
                                                    }}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="signature">
                            <div class="signature__content">
                                <span
                                    >Akceptuję warunki wyceny i proszę o
                                    realizację.</span
                                >
                                <span>Data: </span>
                                <span>Podpis:</span>
                            </div>
                        </div>
                        <div class="user-info">
                            <div class="column-1">
                                <div class="column-1__img-wrapper">
                                    <img
                                        src="@/assets/img/global/estimateBoxPhoto.png"
                                        alt="entelli logo"
                                    />
                                </div>
                            </div>
                            <div class="column-2">
                                <span>
                                    {{
                                        getSettingsDetails.estimatesPDFBoxText
                                    }}</span
                                >
                            </div>
                        </div>
                        <div class="contact">
                            <span class="line-1">Kontakt</span>
                            <div class="contact__content">
                                <span class="text-normal">
                                    {{
                                        getSettingsDetails.estimatesPDFFooterText
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-container>
        <Preloader v-if="preloader" />

        <Snackbars />
    </v-app>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Preloader from './../../../../../../Global/Preloader/Preloader.vue'
import store from '../../../../../../../store'
import jspdf from 'jspdf'
import domtoimage from 'dom-to-image'
import moment from 'moment'
export default {
    data() {
        return {
            routeType: '',
            path: `${process.env.VUE_APP_SERVER}/secured/logo/`,
            forTechnic: true,
            vat: 23,
            partsBrutto: 0,
            isPrice: false,
            preloader: false,
            x: '',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
            emailMenu: false,
            representativeMail: '',
            emailSend: false,
            snackBarsItems: [],
        }
    },
    computed: {
        ...mapGetters([
            'getServiceDetails',
            'getSettingsDetails',
            'getPrevIntervention',
            'getEstimates',
            'getProfileDetails',
        ]),
        location: {
            get() {
                let location = this.getServiceDetails.device.client.branches.find(
                    el => el._id === this.getServiceDetails.device.branch
                )
                return location
            },
        },
        contractors: {
            get() {
                let representatives = this.$get(
                    this.getServiceDetails,
                    'device.client.representatives',
                    []
                )
                return representatives.filter(
                    representative => representative.email
                )
            },
        },
        currentDate: {
            get() {
                return moment().format('DD.MM.YYYY')
            },
        },
    },
    components: { Preloader },

    methods: {
        ...mapActions([
            'fetchLastService',
            'fetchSettings',
            'sendMail',
            'setEstimateStatus',
        ]),
        ...mapMutations(['setSnackbars']),

        getSummaryCost(partsCost, servicesCost, transportCost) {
            let total = 0.0
            total = partsCost + servicesCost + transportCost
            return total.toFixed(2)
        },

        genCode(len) {
            const chars =
                '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let code = ''
            for (let i = 0; i < len; i++) {
                let index = Math.floor(Math.random() * chars.length)
                code += chars[index]
            }
            return code
        },

        printDoc() {
            window.print()
        },

        async sendEstimatesMessages() {
            try {
                this.emailSend = true
                await this.fetchSettings({ type: 'add' })
                const companyServiceEmail = this.getSettingsDetails
                    .companyServiceEmail
                const shortClientName = this.getServiceDetails.client.name

                const applicationLogo = `https://${this.getSettingsDetails.applicationLink}/secured/logo/client_logo.png`

                const insideCompanyEmailData = {
                    alertSource: { type: 'ESTIMATES_SEND_TO_BIUROMAX' },
                    mail: companyServiceEmail,
                    details: {
                        clientName: shortClientName,
                    },
                    creator: {
                        name: this.$route.query.userOfficialName,
                        lastName: this.$route.query.userOfficialLastName,
                    },
                    applicationLogo: applicationLogo,
                    serviceId: this.$route.query.id,
                }

                const outsideCompanyEmailData = {
                    alertSource: { type: 'ESTIMATES_SEND_TO_CLIENT' },
                    mail: this.$route.query.mail,
                }

                const resp = await this.sendEmail(insideCompanyEmailData)

                const resp2 = await this.sendServicePrint(
                    outsideCompanyEmailData
                )

                if (resp) {
                    this.$toast.success('Email wysłany do użytkownika Entelli')
                } else {
                    this.$toast.success(
                        'Wystąpił błąd w wysyłaniu wiadomości do użytkownika Entelli'
                    )
                }
                if (resp2) {
                    this.$toast.success('Email wysłany do klienta')
                } else {
                    this.$toast.error(
                        'Wystąpił błąd w wysyłaniu wiadomości do klienta'
                    )
                }
                this.preloader = false
            } catch (err) {
                console.log(err)
            }
        },
        async sendEmail(data) {
            try {
                const resp = await this.sendMail({
                    mailBody: {
                        case: 'estimates',
                        mail: data.mail,
                        alertSource: data.alertSource,
                        subject: data.subject,
                        details: {
                            clientName: data.details.clientName,
                            clientEmail: data.mail,
                            realisationTime: moment().format(
                                'DD-MM-YYYY, HH:mm '
                            ),
                        },
                        creator: data.creator,
                        applicationLogo: data.applicationLogo,
                        serviceId: data.serviceId,
                    },
                })

                return resp
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async sendServicePrint(data) {
            let mail = data.mail

            try {
                let doc
                try {
                    const dataUrl = await domtoimage.toPng(this.$refs.content)

                    var img = new Image()
                    img.src = dataUrl
                    doc = new jspdf('p', 'mm', [297, 210])
                    doc.addImage(img, 'JPEG', 0, 0)
                } catch (err) {
                    console.log(err)
                }

                let form = new FormData()
                form.append('case', 'estimates')
                form.append('mail', `${mail}`)
                form.append('data', JSON.stringify(data))
                form.append('index', `${this.getServiceDetails.index}`)
                form.append('code', `${this.genCode(8)}`)
                form.append('doc', doc.output('blob'))

                let res = await new this.Rest('POST', '/service/sendEstimate')
                    .setBody(form)
                    .send()

                return true
            } catch (error) {
                console.error(error.response?.data?.message)
                if (error.response?.data?.message) {
                    this.$toast.error(error.response?.data?.message)
                } else {
                    this.$toast.error('Coś poszło nie tak...')
                }

                return false
            }
        },

        downloadWithCSS(data) {
            /** WITH CSS */
            domtoimage
                .toPng(this.$refs.content)
                .then(function (dataUrl) {
                    var img = new Image()
                    img.src = dataUrl
                    const doc = new jspdf('p', 'mm', [297, 210])
                    doc.addImage(img, 'JPEG', 0, 0)
                    const date = new Date()

                    const filename =
                        data.index +
                        '_' +
                        date.getFullYear() +
                        ('0' + (date.getMonth() + 1)).slice(-2) +
                        ('0' + date.getDate()).slice(-2) +
                        ('0' + date.getHours()).slice(-2) +
                        ('0' + date.getMinutes()).slice(-2) +
                        '.pdf'
                    doc.save(filename)
                })
                .catch(function (error) {
                    console.error('Coś poszło nie!', error)
                })
        },

        checkType() {
            const data = this.getServiceDetails
            this.routeType = this.$route.query.type
            if (this.$route.query.type === 'download') {
                this.downloadWithCSS(data)
            }
            if (this.$route.query.type === 'print') {
                this.printDoc()
            }
            if (this.$route.query.type === 'email' && !this.emailSend) {
                this.preloader = true
                this.sendEstimatesMessages()
                return
            }
        },
    },
    props: {
        type: {
            type: String,
        },
    },

    updated: function () {
        this.$nextTick(function () {
            if (this.$refs.content && !this.emailSend) {
                this.checkType()
            }
        })
    },

    beforeUpdate() {
        this.test()
    },
    beforeMount() {
        store.dispatch('fetchSettings', {
            type: 'add',
        })
    },
}
</script>

<style lang="scss" scoped>
body {
    width: 230mm;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    font-size: 12pt;
    background: rgb(204, 204, 204);
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.hidden-scroll {
    height: 100vh !important;
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
}
.main-page {
    width: 210mm;
    min-height: 267mm;
    margin: 10mm auto;
    background: white;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
.sub-page {
    height: 297mm;
    margin: 0px 30px;
}

.header {
    display: grid;
    grid-template-columns: 2fr 4fr;
    padding-bottom: 3px;
    color: black;
    border-bottom: 1px solid grey;

    .logo {
        padding: 10px;
        margin-top: 30px;
        margin-left: 20px;

        img {
            max-width: 203px;
            max-height: 45px;
            display: block;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        text-align: end;
        padding-right: 30px;
        padding-top: 30px;
        font-size: 0.8rem;

        &--line--1 {
            font-weight: bold;
        }
        &--line--2,
        &--line--3 {
            font-size: 0.7rem;
        }
        &--line--4 {
            margin-top: 10px;
            font-size: 0.7rem;
        }

        &--line--5 {
            font-size: 0.7rem;
        }
    }
}

.date-info {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 0.7rem;
    text-align: end;
}

.estimate__container {
    margin: 0 30px;
    &__topic {
        margin-top: 5px;
        font-size: 1.9rem;
        color: #b30000;
        border-bottom: 6px solid #b30000;
    }

    &__costs__wrapper {
        margin-top: 10px;
        padding-left: 5px;
        display: grid;
        grid-template-columns: 2fr 5fr;

        .text-summary {
            font-size: 14px;
            font-weight: 500;
            color: #d2770b;
        }
        .text-bold {
            font-weight: bold;
            font-size: 0.75rem;
        }
        .text-normal {
            font-size: 0.7rem;
        }
        .text-cost {
            font-size: 0.75rem;
            color: grey;
        }
        .min-wrapper {
            display: flex;
            justify-content: space-between;
        }
        .break-line {
            height: 1px;
            background: rgb(128, 128, 128, 0.45);
        }

        .column-1 {
            display: flex;
            flex-direction: column;

            .column__details {
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                img {
                    width: 15%;
                }

                .line-1 {
                    text-decoration: underline;
                    color: #0047b3;
                    font-size: 1.2em;
                    margin-bottom: 5px;
                }
            }
            .column__details:nth-child(2) {
                margin-top: 50px;
            }
        }
        .column-2 {
            padding-left: 100px;
            .column__details {
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                &--certificate-info {
                    font-size: 0.7rem;
                    color: #2f5da1;
                }

                .line-1 {
                    text-decoration: underline;
                    color: #0047b3;
                    font-size: 1.2rem;
                }

                .cost {
                    color: grey;
                    padding-left: 10px;
                    padding-top: 10px;
                    &--total {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .signature {
        margin-top: 30px;
        height: 50px;
        border: 1px solid black;
        font-size: 0.7rem;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;

        &__content {
            flex-grow: 1;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
        }
        &__content > span:first-child {
            padding-left: 10px;
            flex-grow: 1;
        }
        &__content > span:nth-child(2) {
            flex-grow: 3;
        }
        &__content > span:nth-child(3) {
            flex-grow: 2;
        }
    }
    .user-info {
        margin-top: 10px;
        min-height: 150px;
        border-top: 6px solid #b30000;
        border-bottom: 6px solid #b30000;
        font-size: 0.7rem;
        display: grid;
        grid-template-columns: 3fr 5fr;
        .column-1 {
            &__img-wrapper {
                width: 100%;
                height: 100%;
                img {
                    width: 200px;
                    height: 150px;
                    object-fit: contain;
                }
            }
        }
        .column-2 {
            padding: 30px;
            display: flex;
            align-items: center;
        }
    }

    .contact {
        margin-top: 30px;
        .line-1 {
            text-decoration: underline;
            color: #0047b3;
            font-size: 1.2em;
            margin-bottom: 5px;
        }
        .contact__content {
            display: flex;
            flex-direction: column;
            .text-normal {
                font-size: 0.8rem;
            }
        }
    }
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
    }
    .main-page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        height: 90%;
        width: 90%;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
    .sub-page {
        width: 200mm;
        height: 290mm;
    }
}
</style>
